<template>
  <div class="archive-settings">
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="title">{{item.title}}</div>
        <div class="desc">{{item.desc}}</div>

        <div class="item">
          <div class="i-l">
            <a-button @click="initRadioModal(item)">{{item.selectVal ? '重新选择' : item.button}}</a-button>
          </div>
          <div class="i-r" v-if="item.selectVal">
            已选择{{item.selectType}}<span>{{item.selectDesc}}</span>
          </div>
        </div>
      </li>
    </ul>

    <div class="btn-wrapper">
      <div class="wrapper">
        <a-button type="primary">保存</a-button>
      </div>
    </div>


    <a-modal
      centered
      :title="radioModalParams.title"
      :visible="radioModalParams.visible"
      :confirm-loading="radioModalParams.confirmLoading"
      :cancelText="radioModalParams.cancelText"
      :okText="radioModalParams.okText"
      :maskClosable="radioModalParams.maskClosable"
      :destroyOnClose="radioModalParams.destroyOnClose"
      :width="radioModalParams.width"
      @ok="radioModalParams.confirm"
      @cancel="radioModalParams.cancel"
    > 
      <div>
        <a-radio-group :default-value="radioModalParams.list.length ? radioModalParams.list[0].value : ''" @change="onChange">
          <a-radio-button :style="radioStyle" :value="item.value" v-for="(item, index) in radioModalParams.list" :key="index">
            {{item.desc}}
          </a-radio-button>
        </a-radio-group>
      </div>
    </a-modal>


  </div>

</template>

<script>

const mockData = [
  {title: '添加到员工材料附件', desc: '提前设置添加的材料附件类型，签署完成后将自动归档', button: '选择材料附件类型', selectType: '材料附件', selectVal: '', selectDesc: '', itemValue: [
    {value: '1', desc: '个人证件照1 '}, {value: '2', desc: '个人证件照2 '}, {value: '3', desc: '个人证件照3 '}, {value: '4', desc: '个人证件照4 '}, {value: '5', desc: '个人证件照5 '},
  ]},
  {title: '添加到员工合同记录', desc: '提前设置添加到员工合同记录，签署完成后将自动归档', button: '选择合同类型', selectType: '合同类型', selectVal: '', selectDesc: '', itemValue: [
    {value: '1', desc: '以完成一定工作任务为期限的劳动合同1  '}, {value: '2', desc: '以完成一定工作任务为期限的劳动合同 2 '}, {value: '3', desc: '以完成一定工作任务为期限的劳动合同 3 '}, {value: '4', desc: '以完成一定工作任务为期限的劳动合同 4 '}, {value: '5', desc: '以完成一定工作任务为期限的劳动合同 5 '},
  ]},
]

export default {
    name: "archive-settings",
    data() {
      return {
        spinning: false,
        list: mockData,
        radioStyle: {
          marginRight: '20px',
          marginBottom: '20px',
          height: '30px',
          lineHeight: '30px',
        },
        curRadioVal: null,
        radioModalParams: {
          destroyOnClose: true,
          maskClosable: false,
          confirmLoading: false,
          visible: false,
          width: 720,
          title: '',
          list: [],
          cancelText: '重置',
          okText: '确认',
          confirm: () => {},
          cancel: () => {},
        },
        
      }
    },
    created() {

    },
    mounted() {
  
    },
    methods: {
      
      initRadioModal(item) {
        let _this = this
        this.curRadioVal = item.itemValue[0].value
        this.radioModalParams.title = item.button
        this.radioModalParams.list = item.itemValue
        this.radioModalParams.confirm = function() {
          item.selectVal = _this.curRadioVal
          item.selectDesc = item.itemValue.filter(it => it.value === _this.curRadioVal)[0].desc
          _this.radioModalParams.visible = false
        }
        this.radioModalParams.cancel = function() {
          item.selectVal = ''
          item.selectDesc = ''
          _this.radioModalParams.visible = false
        }
        this.radioModalParams.visible = true
      },
      onChange(e) {
        console.log('radio checked', e.target.value);
        this.curRadioVal = e.target.value
      },
    },
    components: {

    }
}
</script>

<style lang="less" scope>
@import "../../../../styles/common.less";

.archive-settings{
  padding: 24px 0 102px;
  margin: 0 auto;
  width: 80%;
  min-width: 1000px;
  ul{
    li{
      list-style: none;
      margin-bottom: 32px;
      .title{
        margin-bottom: 12px;
        padding-bottom: 16px;
        color: #1c2438;
        font-size: 16px;
        border-bottom: 1px solid #f0f4f8;
        line-height: 1;
      }
      .desc{
        margin-bottom: 24px;
        font-size: 12px;
        line-height: 1.5em;
        color: #80848f;
      }
      .item{
        margin-bottom: 12px;
        line-height: 32px;
        font-size: 14px;
        #flex;
        .i-l{

        }
        .i-r{
          #mrl10;
          span{
            color: #f90;
          }
        }
      }
    }
  }
  .btn-wrapper{
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0;
    z-index: 20;
    background-color: #ecf5ff;
    .wrapper{
      // #tac;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

</style>
